import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import { slide as MenuMobile } from "react-burger-menu";
import {NavLink} from "react-router-dom";

export default props => {
    const { t } = useTranslation()
    const [isOpenMenu, setMenu] = useState(false);
  return (
    // Pass on our props
    <MenuMobile isOpen={isOpenMenu} onClose={() => setMenu(false)} onOpen={() => setMenu(true)} width={ '100%' } {...props}>
      <NavLink activeStyle={{ opacity: '0.5' }} onClick={() => setMenu(false)} className="menu-item" to="/" exact={true}>{t("menu.home")}</NavLink>
      <NavLink activeStyle={{ opacity: '0.5' }} onClick={() => setMenu(false)} className="menu-item" to="/artisan">{t("menu.artisan")}</NavLink>
      <NavLink activeStyle={{ opacity: '0.5' }} onClick={() => setMenu(false)} className="menu-item" to="/service">{t("menu.service")}</NavLink>
      <NavLink activeStyle={{ opacity: '0.5' }} onClick={() => setMenu(false)} className="menu-item" to="/mission">{t("menu.mission")}</NavLink>
      <NavLink activeStyle={{ opacity: '0.5' }} onClick={() => setMenu(false)} className="menu-item" to="/equipe">{t("menu.team")}</NavLink>
      <NavLink activeStyle={{ opacity: '0.5' }} onClick={() => setMenu(false)} className="menu-item" to="/contact">{t("menu.contact")}</NavLink>
      <LanguageSelector className="mobileselector"/>
    </MenuMobile>
  );
};