import React from 'react';

import styles from './Header.module.scss';
import './Header.scss';
import logo from '../../img/logo.svg';



import Nav from '../Nav/Nav'

const Header = () => {
    return (
        <section className={styles.mainheader}>
            <div className={`${styles.container} container`}>
                <a href="/">
                    <img src={logo} alt="logo représentant la lettre C dans la lettre Q suivi du mot musique"/>
                </a>
                <Nav/>
            </div>
        </section>
    );
}

export default Header;