import React from 'react';
import styles from './Trombinoscope.module.scss';
import { useTranslation } from "react-i18next";

const Trombinoscope = (props) => {
	const { t } = useTranslation()
	let elements = t(props.keyTranslation, { returnObjects: true });
	return(
		<section className={`${styles.wrapper} container ${props.grid}`}>
			{ Object.entries(elements).map((item,k) => 
			<div className={styles.item} key={k}>
				<img className={styles.trombimg} src={`${t(props.keyTranslation + "."+  k + ".img")}`} alt=""/>
                <div className={styles.info}>
				<h3 className={styles.title}>{t(props.keyTranslation+ "."+   k + ".title")}</h3>
				<h4 className={styles.subtitle}>{t(props.keyTranslation + "."+  k + ".subtitle")}</h4>
                </div>
				<p className={styles.desc}>{t(props.keyTranslation + "."+  k +".desc")}</p>
			</div>) }
		</section>
	)
}
export default Trombinoscope;
