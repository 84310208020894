import React from "react";
import styles from './LanguageSelector.module.scss';
import { useTranslation } from "react-i18next";

export default function LanguageSelector(props) {
  const { i18n } = useTranslation();

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  let button;
  const isFr = i18n.language


  if (isFr === 'fr-FR' || isFr === 'fr-CA' || isFr === 'fr'){
    button = <li onClick={() => changeLanguage("en")}>en</li>
  }else{
    button = <li onClick={() => changeLanguage("fr")}>fr</li>
  }
  return (
    <ul className={`${styles.languageSelector} ${props.className ? props.className : '' }`}>
      {button}
    </ul>
  );
}