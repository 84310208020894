import React from 'react';
import styles from './Bubble.module.scss';
import './Bubble.scss';
import { useTranslation } from "react-i18next";

const Bubble = props => {
    const { t } = useTranslation()
    let rotationDeg1 = props.rotateBubble1
    let rotationDeg2 = props.rotateBubble2
	return(
		<section id={props.id} className={`${styles.wrapper} ${props.className ? props.className : '' }`}>
            <div className={`${styles.container} container`}>
                <div className={styles.bubbleWrapper}>
                <div className={styles.bubble1} style={{transform : 'rotate('+ rotationDeg1 +'deg)'}}></div>
                <div className={styles.bubble2} style={{transform : 'rotate('+ rotationDeg2 +'deg)'}}></div>
                <p className={styles.desc}>{t(props.keyTranslation + ".desc")}</p>
                </div>
            </div>
		</section>
	)
}
export default Bubble;