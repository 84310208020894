import React from 'react';
import styles from './Banner.module.scss';
import { useTranslation } from "react-i18next";

const Banner = () => {
	const { t } = useTranslation()
	return(
		<section className={styles.banner}>
			<div className={`${styles.blocktext} container`}>
				<h1 className={styles.title}>{t("banner.title")}</h1>
				<p className={styles.desc}>{t("banner.desc")}</p>
			</div>
			<div className={styles.illus}></div>
		</section>
	)
}
export default Banner;