import React from 'react';
import styles from './BlockListSubtitle.module.scss';
import './BlockListSubtitle.scss';
import { useTranslation } from "react-i18next";

const BlockDesc = props => {
    const { t } = useTranslation()
    let elements = t(props.keyTranslation + '.list', { returnObjects: true });
	return(
		<section id={props.id} className={`${styles.wrapper} ${props.className ? props.className : '' }`}>
            <div className="container">
                <h2>{t(props.keyTranslation + ".title")}</h2>
                <p className={styles.subtitle}>{t(props.keyTranslation + ".subtitle")}</p>
                <ul className={styles.desc}>
                { Object.entries(elements).map((item,k) => 
			        <li className={styles.item} key={k}>
                        {t(props.keyTranslation + ".list."+  k +".desc")}
			        </li>) }
                </ul>
            </div>
		</section>
	)
}
export default BlockDesc;