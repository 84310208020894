import React, {useEffect} from 'react';
import { useTranslation } from "react-i18next";

import styles from './Service.module.scss';

const Service = () => {
  const { t } = useTranslation()
  const trans = t("servicePage", { returnObjects: true });
  useEffect(() => { 
      document.title = trans.documentTitle
      document.querySelector('meta[name="description"]').content = trans.documentMetaDesc
    });
    let elements = t("servicePage.list", { returnObjects: true });
  return (
    <section className={`${styles.wrapper}`}>
        <div className="container">
            <h1>{t("servicePage.title")}</h1>
            <p>{t("servicePage.desc")}</p>
        </div>
        <section className={`${styles.block} container`}>
          <p>{t("servicePage.text")}</p>
          <ul className={`${styles.list}`} >
            { Object.entries(elements).map((item,k) => 
                <li  key={k}>{t("servicePage.list."+  k + ".text")}</li>
            ) }
            <li><a href="http://ambianceqc.com">Ambiance QC</a></li>
        </ul>
        </section>
		</section>
  )
}
export default Service;