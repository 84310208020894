import React from 'react';

import styles from './Cta.module.scss';
import { useTranslation } from "react-i18next";
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_live_51H4YJTJsz5owPIRPlqQSU06bLdFdF280fAnkXppNJ1RTkEsItDQC9EhwA3CrEsIgDlhOcMn8ytWxEUHxotAacwIv000N0OFKOU');

const Cta = (onChange) => {
    
	const { t } = useTranslation()
    const handleClick = async (event) => {
        // Get Stripe.js instance
        const stripe = await stripePromise;
    
    
        // When the customer clicks on the button, redirect them to Checkout.
        const result = await stripe.redirectToCheckout({
            lineItems: [{price: document.querySelector('input[name="price"]:checked').dataset.stripekey, 
            quantity: 1
        }],
        mode: 'payment',
        successUrl: window.location.origin + '/donation',
        cancelUrl: window.location.origin,
        });
    
        if (result.error) {
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
        }
      };

	return(
		<section className={styles.cta}>
			<div className={`${styles.blocktext} container`}>
				<h2 className={styles.title}>{t("cta.title")}</h2>
				<p className={styles.desc}>{t("cta.desc")}</p>
                <p className={styles.textchoice}>{t("cta.textChoice")}</p>
                <div className={styles.form}>
                    <input id="5" type="radio" value="5" name="price" data-stripekey="price_1IIwdHJsz5owPIRP2Fl9dhPa" defaultChecked/>
                    <label htmlFor="5">5$</label>
                    <input id="10" type="radio" value="10" name="price" data-stripekey="price_1IIwdBJsz5owPIRPwtNS1nL5" />
                    <label htmlFor="10">10$</label>
                    <input id="15" type="radio" value="15" name="price" data-stripekey="price_1IIwd6Jsz5owPIRP3mqRlLux" />
                    <label htmlFor="15">15$</label>
                    <input id="20" type="radio" value="20" name="price" data-stripekey="price_1IIwcuJsz5owPIRPCbbvxKki" />
                    <label htmlFor="20">20$</label>
                    <input id="50" type="radio" value="50" name="price" data-stripekey="price_1IIwcpJsz5owPIRPlXuzeedr" />
                    <label htmlFor="50">50$</label>
                    <input id="100" type="radio" value="100" name="price" data-stripekey="price_1IIwcjJsz5owPIRP8NmX00lW"/>
                    <label htmlFor="100">100$</label>
                </div>
                <p className={styles.disclaimer}>{t("cta.disclaimer")}</p>
                <button role="link" className="btn btn--secondary" onClick={handleClick}>{t("cta.btn")}</button>
			</div>
		</section>
	)
}
export default Cta;