import React, {useEffect} from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

import styles from './Donation.module.scss';

const Donation = () => {
  const { t } = useTranslation()
  const trans = t("servicePage", { returnObjects: true });
  useEffect(() => { 
      document.title = trans.documentTitle
      document.querySelector('meta[name="description"]').content = trans.documentMetaDesc
    });
    
  return (
    <section className={`${styles.wrapper}`}>
        <div className="container">
            <h1>{t("donationPage.title")}</h1>
            <p>{t("donationPage.desc")}</p>
        </div>
        <section className={`${styles.block} container`}>
        <Link to="/" className="btn btn--primary" href="/">{t("donationPage.btn")}</Link>
        </section>
		</section>
  )
}
export default Donation;