import React from 'react';
import styles from './BlockDesc.module.scss';
import './BlockDesc.scss';
import { useTranslation } from "react-i18next";

const BlockDesc = props => {
    const { t } = useTranslation()
	return(
		<section id={props.id} className={`${styles.wrapper} ${props.className ? props.className : '' }`}>
            <div className="container">
                <h2>{t(props.keyTranslation + ".title")}</h2>
                <p className={styles.desc}>{t(props.keyTranslation + ".desc")}</p>
            </div>
		</section>
	)
}
export default BlockDesc;