import React, {useEffect} from 'react';
import { useTranslation } from "react-i18next";

import styles from './Mission.module.scss';

const Mission = () => {
  const { t } = useTranslation()
  const trans = t("missionPage", { returnObjects: true });
  useEffect(() => { 
      document.title = trans.documentTitle
      document.querySelector('meta[name="description"]').content = trans.documentMetaDesc
    });
    
  return (
    <section>
        <div className="page-header container">
            <h1>{t("missionPage.title")}</h1>
            <p>{t("missionPage.desc")}</p>
        </div>
        <section className={`${styles.block} container`}>
          <p>{t("missionPage.text")}</p>
          <p className={styles.blockText}>{t("missionPage.text1")}</p>
          <p className={styles.blockText}>{t("missionPage.text2")}</p>
          <p className={styles.blockText}>{t("missionPage.text3")}</p>
        </section>
		</section>
  )
}
export default Mission;