import React, {useEffect} from 'react';
import { useTranslation } from "react-i18next";

import styles from './Artisan.module.scss';

const Artisan = () => {
  const { t } = useTranslation()
  const trans = t("artisanPage", { returnObjects: true });
  useEffect(() => { 
      document.title = trans.documentTitle
      document.querySelector('meta[name="description"]').content = trans.documentMetaDesc
    });
    let elements = t("artisanPage.list", { returnObjects: true });
  return (
    <section>
        <div className="page-header container">
            <h1>{t("artisanPage.title")}</h1>
            <p>{t("artisanPage.desc")}</p>
        </div>
        <section className={`${styles.block} container`}>
            <p>{t("artisanPage.text")}</p>
            <p>{t("artisanPage.textPlatform")}</p>
            <a href="https://artistes.qcmusique.com" target="_blank" rel="noopener noreferrer" className="btn btn--primary">{t("artisanPage.btn")}</a>
            <ul className={`${styles.list}`} >
            { Object.entries(elements).map((item,k) => 
                <li  key={k}>{t("artisanPage.list."+  k + ".text")}</li>
            ) }
            </ul>
        </section>
		</section>
  )
}
export default Artisan;