import React from 'react';
import {NavLink} from "react-router-dom";
import { useTranslation } from "react-i18next";

import LanguageSelector from '../LanguageSelector/LanguageSelector';
import styles from './Nav.module.scss';


const Nav = () => {
    const { t } = useTranslation()
    return(
            <nav>
                <ul className={styles.list}>
                    <li><NavLink activeStyle={{ color: '#125AED' }} to="/" exact={true}>{t("menu.home")}</NavLink></li>
                    <li><NavLink activeStyle={{ color: '#125AED' }} to="/artisan">{t("menu.artisan")}</NavLink></li>
                    <li><NavLink activeStyle={{ color: '#125AED' }}to="/service">{t("menu.service")}</NavLink></li>
                    <li><NavLink activeStyle={{ color: '#125AED' }}to="/mission">{t("menu.mission")}</NavLink></li>
                    <li><NavLink activeStyle={{ color: '#125AED' }}to="/equipe">{t("menu.team")}</NavLink></li>
                    <li><NavLink to="/contact" className="btn btn--primary btn--small">{t("menu.contact")}</NavLink></li>
                    <LanguageSelector/>
                </ul>
            </nav>
    );
}

  export default Nav;