import React, {useEffect} from 'react';
import { useTranslation } from "react-i18next";

import styles from './Contact.module.scss';

const Contact = () => {
  const { t } = useTranslation()
  const trans = t("contactPage", { returnObjects: true });
  useEffect(() => { 
      document.title = trans.documentTitle
      document.querySelector('meta[name="description"]').content = trans.documentMetaDesc
    });
    let elements = t("contactPage.list", { returnObjects: true });
  return (
    <section className={`${styles.wrapper}`}>
        <div className="container">
            <h1>{t("contactPage.title")}</h1>
            <p>{t("contactPage.desc")}</p>
        </div>
        <section className={`${styles.list} container grid-col--2`}>
        { Object.entries(elements).map((item,k) => 
          <div className={`${styles.list__item}`} key={k}>
            <h3>{t("contactPage.list."+  k + ".title")}</h3>
            <a href={"mailto:" + t("contactPage.list."+  k + ".mail")}>{t("contactPage.list."+  k + ".mail")}</a>
          </div>
        ) }
        </section>
		</section>
  )
}
export default Contact;