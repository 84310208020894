import React, {useEffect, useState} from 'react';
import BlockDesc from '../components/BlockDesc/BlockDesc';
import Trombinoscope from '../components/Trombinoscope/Trombinoscope';

const Team = () => {
  const [title] = useState("QC Musique | Notre équipe");
    useEffect(() => { document.title = title}, [title]);
    
  return (
    <div>
        <BlockDesc id="wise" keyTranslation="wise"/>
        <Trombinoscope keyTranslation="trombinoscopeWise" grid="grid-col--3"/>
        <BlockDesc id="director" keyTranslation="director"/>
        <Trombinoscope keyTranslation="trombinoscopeDirector" grid="grid-col--2"/>
        {/*<BlockDesc id="bod" keyTranslation="bod"/>*/}
    </div>
  )
}
export default Team;