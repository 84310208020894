import React from 'react';
import styles from './Footer.module.scss';
import instagram from '../../img/instagram.svg';
import facebook from '../../img/facebook.svg';
import { useTranslation } from "react-i18next";

const Footer = props => {
    const { t } = useTranslation()
	return(
		<footer id={props.id} className={styles.wrapper}>
            <div className={`${styles.info} container`}>
                <p>{t("footer.desc")}</p>
                <a href="mailto:info@qcmusique.ca">info@qcmusique.ca</a>
                <ul className={styles.list}>
                    <li><a href="https://www.instagram.com/qcmusique/"><img src={instagram} alt=""/></a></li>
                    <li><a href="https://www.facebook.com/qcmusique"><img src={facebook} alt=""/></a></li>
                </ul>
            </div>
		</footer>
	)
}
export default Footer;