import React from 'react';
import Konami from 'react-konami-code';
import {
	BrowserRouter as Router,
    Switch,
    Route
  } from "react-router-dom";
  
import Home from '../../pages/Home'
import Contact from '../../pages/Contact'
import Team from '../../pages/Team';
import Service from '../../pages/Service';
import Mission from '../../pages/Mission';
import Artisan from '../../pages/Artisan';
import Donation from '../../pages/Donation';
import NotFound from '../../pages/NotFound';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import SideBar from '../MenuMobile/MenuMobile';

import './App.scss';


const App = () => {

	/*useEffect(() => {
		new LocomotiveScroll({
		el: document.querySelector('[data-scroll-container]'),
		smooth: true})
	});*/
	

	const easterEgg = () => {
		window.location.replace('/login.php')
	}


	return (
		<Router>
		<div className="App" id="App" >
			<SideBar pageWrapId={"page-wrap"} outerContainerId={"App"} />
			<div id="page-wrap" className="main-wrapper">
				<Header/>
				<main>
					<Switch>
						<Route exact path="/">
							<Home />
						</Route>
						<Route path="/contact">
							<Contact />
						</Route>
						<Route path="/equipe">
							<Team />
						</Route>
						<Route path="/service">
							<Service />
						</Route>
						<Route path="/mission">
							<Mission />
						</Route>
						<Route path="/artisan">
							<Artisan />
						</Route>
						<Route path="/donation">
							<Donation />
						</Route>
						<Route component={NotFound} />
					</Switch>
				</main>
				<Footer id="contact" />
				<Konami action={easterEgg} />
			</div>
		</div>
		</Router>
	)
}
 
export default App;
