import React, {useEffect} from 'react';
import { useTranslation } from "react-i18next";
import Banner from '../components/Banner/Banner';
//import BlockList from '../components/BlockList/BlockList';
import Bubble from '../components/Bubble/Bubble';
import BlockListSubtitle from '../components/BlockListSubtitle/BlockListSubtitle';
import Cta from '../components/Cta/Cta';

import styles from './Home.module.scss';

const Home = () => {
  const { t } = useTranslation()
  const trans = t("homePage", { returnObjects: true });
  useEffect(() => { 
      document.title = trans.documentTitle
      document.querySelector('meta[name="description"]').content = trans.documentMetaDesc
    });
  return (
    <div>
        <Banner/>
        {/*<BlockList id="problematic" keyTranslation="problematic"/>*/}
        <h2 className={`${styles.probTitle} container`}>{trans.probTitle}</h2>
        <section className={styles.sectionBubble}>
            <Bubble id="problematic1" keyTranslation="problematic1" rotateBubble1="0" rotateBubble2="0"/>
            <Bubble id="problematic2" keyTranslation="problematic2" rotateBubble1="135" rotateBubble2="175"/>
        </section>
        <section className={styles.sectionBubble}>
            <Bubble id="problematic3" keyTranslation="problematic3" rotateBubble1="75" rotateBubble2="45"/>
        </section>
        <BlockListSubtitle id="solution" keyTranslation="solution"/>
        <Cta />
    </div>
  )
}
export default Home;